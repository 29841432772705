"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const operators_1 = require("rxjs/operators");
const defaultState = {
    popit: Array(28).fill(false),
    reverse: false
};
class PopitCtrl {
    constructor($scope, $timeout, SoundService, ConfigService, ModalServiceFactory) {
        var _a;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.SoundService = SoundService;
        this.ConfigService = ConfigService;
        this.ModalServiceFactory = ModalServiceFactory;
        this.state = JSON.parse(localStorage.getItem(`${this.constructor.name}_state`) || 'null') || ng.copy(defaultState);
        this.$scope.$watch('$ctrl.state', (state) => {
            localStorage.setItem(`${this.constructor.name}_state`, JSON.stringify(state));
        }, true);
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            if (!this.state.popit.some((item) => item == true) || !localStorage.getItem(`${this.constructor.name}_state`)) {
                // event.target?.dispatchEvent(e)
                window.location.href = currentTarget.href;
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        localStorage.removeItem(`${this.constructor.name}_state`);
                        window.location.href = currentTarget.href;
                    }
                });
            }
        })).subscribe();
    }
    $onInit() {
    }
    newGame() {
        if (!this.state.popit.some((item) => item == true) || !localStorage.getItem(`${this.constructor.name}_state`)) {
            this.state = ng.copy(defaultState);
        }
        else {
            this._confirmNewGame((result) => {
                if (result) {
                    this.state = ng.copy(defaultState);
                }
            });
        }
    }
    reverse() {
        this.state.reverse = !this.state.reverse;
        this.state.popit = this.state.popit.map((item) => !item);
        this.SoundService.play('flip');
    }
    popit(index) {
        if (!this.state.reverse) {
            if (!this.state.popit[index]) {
                this.state.popit[index] = true;
                this.SoundService.play('up');
            }
        }
        else {
            if (!this.state.popit[index]) {
                this.state.popit[index] = true;
                this.SoundService.play('up');
            }
        }
    }
    _confirmNewGame(callback) {
        return this.ModalServiceFactory.open({
            id: 'popit_new_game',
            component: "confirm-comp",
            scope: this.$scope,
            extraContext: {
                settings: {}
            }
        }).then((result) => {
            if (result) {
                callback ? callback(result) : null;
            }
            else {
                throw { error: 'cancel' };
            }
        });
    }
}
PopitCtrl.$inject = ['$scope', '$timeout', 'SoundService', 'ConfigService', 'ModalServiceFactory'];
const appModule = ng.module('app');
appModule.component('gamePopit', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: PopitCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'SoundServiceProvider', 'ConfigServiceProvider',
    (WsServiceProvider, SoundServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('popit/');
        SoundServiceProvider.setSound({
            'up': require('./sounds/up.mp3').default,
            'flip': require('./sounds/flip.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            sound_effects: true,
        });
    }]);
